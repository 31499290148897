import {v4 as _uuid} from "../vendor/uuid";
export var uuid = _uuid;

export var ENV = "browser";
try {
  window
  window["utils"] = exports;
} catch(err) {
  ENV = "node";
}

export var DEBUG:any = {

};

if(ENV === "browser") window["DEBUG"] = DEBUG;

export function builtinId(name:string):string {
  return `AUTOGENERATED ${name} THIS SHOULDN'T SHOW UP ANYWHERE`;
}

type TemplateStringTag = (strings:string[], ...values:any[]) => string
interface unpad {
  (indent:number): TemplateStringTag
  memo: {[indent:number]: TemplateStringTag}
}
export var unpad:unpad = <any>function(indent) {
  if(unpad.memo[indent]) return unpad.memo[indent];
  return unpad.memo[indent] = function(strings, ...values) {
    if(!strings.length) return;
    let res = "";
    let ix = 0;
    for(let str of strings) res += str + (values.length > ix ? values[ix++] : "");

    if(res[0] === "\n") res = res.slice(1);
    let charIx = 0;
    while(true) {
      res = res.slice(0, charIx) + res.slice(charIx + indent);
      charIx = res.indexOf("\n", charIx) + 1;
      if(!charIx) break;
    }
  return res;
  }
};
unpad.memo = {};

export function repeat(str:string, length:number) {
  let len = length / str.length;
  let res = "";
  for(let ix = 0; ix < len; ix++)  res += str;
  return (res.length > length) ? res.slice(0, length) : res;
}
export function underline(startIx, length) {
  return repeat(" ", startIx) + "^" + repeat("~", length - 1);
}

export function capitalize(word:string):string {
  return word[0].toUpperCase() + word.slice(1)
}

export function titlecase(name:string):string {
  return name.split(" ").map(capitalize).join(" ");
}

let _slugifyReplacements = {
  "-": "dash",
  "_": "under",
  "$": "dollar",
  "&": "and",
  "+": "plus",
  ",": "comma",
  "/": "slash",
  ":": "colon",
  ";": "semicolon",
  "=": "equals",
  "?": "question",
  "@": "at",
  "<": "lt",
  ">": "gt",
  "#": "hash",
  "%": "percent",
  "{": "opencurly",
  "}": "closecurly",
  "|": "pipe",
  "\\": "whack",
  "^": "caret",
  "~": "tilde",
  "[": "openbracket",
  "]": "closebracket",
  "`": "grave"
};
let _deslugifyReplacements = {};
for(let char in _slugifyReplacements) {
  _deslugifyReplacements[_slugifyReplacements[char]] = char;
}

// Slugify encodes a uri component in a fairly human readable fashion
export function slugify(text:string):string {
  let url = "";
  for(let char of text) {
    let replacement = _slugifyReplacements[char];
    if(char === " ") {
      url += "_";
      
    } else if(replacement) {
      url += `-'${replacement}-`;
      
    } else {
      url += char;
    }
  }
  return encodeURIComponent(url);
}

export function deslugify(url:string):string {
  let text = [];
  for(let word of url.split("_")) {
    if(word.indexOf("-") === -1) {
      text.push(word);
      continue;
    }

    let replaced = "";
    let tokens = word.split("-");
    replaced += tokens.shift();
    let tail = tokens.pop();
    for(let token of tokens) {
      let replacement = _deslugifyReplacements[token.slice(1)];
      if(replacement && token.indexOf("'") === 0) {
        replaced += replacement;
      } else {
        replaced += token;
      }
    }
    replaced += tail;
    text.push(replaced);
  }

  return decodeURIComponent(text.join(" "));
}

export var string = {
  unpad,
  repeat,
  underline,
  capitalize,
  titlecase,
  slugify,
  deslugify
};

export function tail(arr) {
  return arr[arr.length - 1];
}

export var array = {
  tail
};

export function coerceInput(input) {
  // http://jsperf.com/regex-vs-plus-coercion
  if(input === "") return input;
  else if(typeof input === "object") return input;
  else if (!isNaN(+input)) return +input;
  else if (input === "true") return true;
  else if (input === "false") return false;
  return input;
}

// Shallow copy the given object.
export function copy(obj) {
  if(!obj || typeof obj !== "object") return obj;
  if(obj instanceof Array) return obj.slice();
  let res = {};
  for(let key in obj) res[key] = obj[key];
  return res;
}

export function mergeObject(root, obj) {
  for(let key in obj) {
    root[key] = obj[key];
  }
  return root;
}

export function autoFocus(node, elem) {
  if(!node.focused) {
    node.focused = true;
    node.focus();
  }
}

export var KEYS = {
  ESC: 27,
  ENTER: 13,
  UP: 38,
  DOWN: 40,
  BACKSPACE: 8,
  "]": 221,
}

// FROM: http://stackoverflow.com/questions/1125292/how-to-move-cursor-to-end-of-contenteditable-entity/3866442#3866442
export function setEndOfContentEditable(contentEditableElement) {
  var range,selection;
  if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
  {
    range = document.createRange();//Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
    range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection();//get the selection object (allows you to change selection)
    selection.removeAllRanges();//remove any selections already made
    selection.addRange(range);//make the range you have just created the visible selection
  }
}

// LCG courtesy of <https://gist.github.com/Protonk/5389384>
export function srand(z) {
  let m = Math.pow(2, 24), a = 16598013, c = 12820163;
  return () => z = (a * z + c) % m / m;
}

export function shuffle(arr:any[], rand = Math.random) {
  for(let i = arr.length - 1; i > 0; i -= 1) {
    let j = Math.floor(rand() * (i + 1));
    let tmp = arr[i];
    arr[i] = arr[j];
    arr[j] = tmp;
  }
  return arr;
}

export function sortByField(field, direction = 1):(a, b) => number {
  let back = -1 * direction;
  let fwd = direction;
  return (a, b) =>
    (a[field] === b[field]) ? 0 :
    (a[field] > b[field]) ? back :
    (a[field] < b[field]) ? fwd :
    (a[field] === undefined) ? fwd : back;
}
export function sortByLookup(lookup:{}, direction = 1):(a, b) => number {
  let back = -1 * direction;
  let fwd = direction;
  return (a, b) =>
    (lookup[a] === lookup[b]) ? 0 :
    (lookup[a] > lookup[b]) ? back :
    (lookup[a] < lookup[b]) ? fwd :
    (lookup[a] === undefined) ? fwd : back;
}

export function location() {
  return window.location.hash.slice(1);
}
